<template>
  <div>
    <v-container class="text_google">
      <base-material-card
        icon="mdi-clipboard-text"
        title="ประวัติการรับราชการ"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>    
          </v-row>
        </v-card>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="personnel_work_historys"
          :search="search"
        >
         <template v-slot:item.government_status="{ item }">
          <span v-if="item.government_status === '0'">ไม่ได้ช่วยราชการ</span>
          <span v-if="item.government_status === '1'">ช่วยราชการ</span>
          </template>         
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ApiKey: "HRvec2021",
      loading: true,
      updateImageDialog: false,
      search: "",
      addpersonnel_work_historydialog: false,
      editpersonnel_work_historydialog: false,
      deletepersonnel_work_historydialog: false,
      showimagedialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      currentPK: null,
      headers: [
      { text: "วิทยาลัย", align: "center", value: "college_name" }, 
        { text: "คำนำหน้าชื่อ", align: "left", value: "title_s" },
        { text: "ชื่อ", align: "left", value: "frist_name" },
        { text: "นามสกุล", align: "left", value: "last_name" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        { text: "สถานะช่วยราชการ", align: "left", value: "government_status" },
        { text: "วันที่", align: "left", value: "date_begin" },
        { text: "สถานที่", align: "left", value: "college_name_work" },
        { text: "สิ้นสุด", align: "center", value: "date_end" },
        { text: "ตำแหน่ง", align: "center", value: "position_s" },
        { text: "หน้าที่อื่น", align: "left", value: "special_duty" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {},
      personnel_work_history: [],
      addpersonnel_work_history: {},
      editpersonnel_work_history: [],
      personnel_work_historys: [],
      personnel_work_history_sub: [],
      branch_s: [],
      education_level: ["ปริญญาตรี", "ปริญญาโท", "ปริญญาเอก"]
    };
  },

  async mounted() {
    this.personnel_work_historysQueryAll();
  },

  methods: {
    async personnel_work_historysQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_work_history.php", {
          ApiKey: this.ApiKey,
          collegeVei: userSession.user_code
        })
        .finally(() => (this.loading = false));
      this.personnel_work_historys = result.data;
    }
  },

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
